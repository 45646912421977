/**
 * Base Colours
 */
$black-grey:#231f20;
$green: #486355;
$light-grey: #f1f1f1;
$grey: #DADADA;
$mid-grey: #ccc;
$red: #E30613;


/**
 * Font Colours
 */
$body-text-color: $black-grey;
$highlight: $red;
$footer-link: white;
$link-text-color: $green;
$link-text-hover: $red;
$menu-link-hover: $red;
$menu-title-hover: $red;
$nav-btn-color: white;
$nav-btn-hover: $red;
$nav-links-sec: $green;
$placeholder-color: $mid-grey;
$title-text-color: $green;


/**
 * Asset Colours
 */
$border-color: $grey;
$btn-hover: $red;
$contact-form-btn: $green;
$datepick-active: $green;
$datepick-hover: $red;
$datepick-today: $light-grey;
$error: $red;
$footer-bg: $green;
$location-bg: $grey;
$menu-bullet-color: $red;
$mobile-menu-btn: $green;
$modal-policy-btn: $green;
$modal-sidebar: $green;
$nav-links: white;
$nav-links-hover: $red;
$nav-open-overlay: rgba(72, 99, 85, 0.7);
$popup-content-bg: $green;
$rellax-bg: $black-grey;
$space-bg: $green;
$symbol-vege: $green;
$title-box-fill: $red;
$title-box-hover: $green;


/**
 * Title sizes
 */
$title-size-xs: 5.5em;
$title-size-md: 6.5em;
$title-size-xl: 7.2em;


/**
 * Bootstrap breakpoints
 */
// Bootstrap additional Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

// Bootstrap additional Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);
 

/**
 * Easing Variables
 */
 $linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
 $ease: cubic-bezier(0.25, 0.1, 0.25, 1);
 $ease-in: cubic-bezier(0.42, 0, 1, 1);
 $ease-out: cubic-bezier(0, 0, 0.58, 1);
 $ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
 
 $ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
 $ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
 $ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
 $ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
 $ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
 $ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
 $ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
 $ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
 
 $ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
 $ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
 $ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
 $ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
 $ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
 $ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
 $ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
 $ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
 
 $ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
 $ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
 $ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
 $ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
 $ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
 $ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
 $ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

 