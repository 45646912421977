%btn-green {
	background-color: $contact-form-btn;
	border: none;
	border-radius: 0;
	color: white;
	font-size: 0.95em;
	letter-spacing: 1px;
	padding: 0.7em;
	text-transform: uppercase;
	width: 100%;
	&:hover {
		background-color: $btn-hover;
	}
}