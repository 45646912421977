.social-media {
	bottom: 9%;
	right: 0.5em;
	margin: 0;
	padding: 0;
	position: fixed;
	text-align: right;
	width: 3em;
	z-index: 5;
	pointer-events: all;
	// opacity: 0;
	@include media-breakpoint-up(xxl) { 
		bottom: 7%;
		right: calc(2em - 15px);
	}
	li {
		list-style: none;
		margin-bottom: 1em;
		transition: all 0.3s ease;
		&:hover {
			padding-right: 0.2em;
		}
	}
}

.social-media svg, .location-icons svg {
	height: 18px;
	width: 18px;
	@include media-breakpoint-up(lg) { 
		height: 21px;
		width: 21px;
	}
	.icon-color {
		fill: $nav-links;
	}
	&.icon-green .icon-color {
		fill: $nav-links-sec;
	}
	&:hover {
		.icon-color {
			fill: $nav-links-hover !important;
		}
	}
}

.nav-green {
	.social-media svg .icon-color {
		fill: $nav-links-sec;
	}
}

.sticky-nav .social-media {
	display: none;
	@include media-breakpoint-up(md) { 
		display: block;
	}
}

.navbar .social-media {
	margin-right: 1.5em;
}

.modal-content .social-media {
	position: fixed !important;
	right: 5.2% !important;
	display: none;
	@include media-breakpoint-up(md) { 
		display: block;
	}
}