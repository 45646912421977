body {
  font-family: 'Avenir';
  font-weight: normal;
  color: $body-text-color;
  font-size: 1em;
  text-align: center;
  @include media-breakpoint-up(md) { 
    font-size: 0.9em;
    text-align: left;
  }
  @include media-breakpoint-up(xxl) { 
    font-size: 1.1em;
    line-height: 1.6em;
  }
  &#home {
    overflow: hidden;
  }
}

a {
  color: $link-text-color;
  &:hover {
    color: $link-text-hover;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: $title-text-color;
  font-family: 'Nilland';
  font-weight: 800;
}

h1 {
  font-size: 2.3em;
  color: white;
  font-weight: 800;
  text-align: center;
}

h2 {
  font-size: 1.8em;
  letter-spacing: 2px;
  text-align: center;
  @include media-breakpoint-up(md) { 
    font-size: 2em;
  }
}

h3 {
  color: $red;
  font-size: 1.3em;
  letter-spacing: 2px;
  margin-bottom: 0.3em;
  @include media-breakpoint-up(md) { 
    font-size: 1.5em;
  }
}

h4 {
  font-size: 1.1em;
  letter-spacing: 2px;
  @include media-breakpoint-up(md) { 
    font-size: 1.2em;
  }
}

h5 {
  font-size: 0.9em;
  color: $highlight;
  font-weight: 800;
}


p {
  &.green {
    color: $green;
  }
}

.modal-policy {
  .modal-content {
    border: 0;
    border-radius: 0;
    padding: 1em;
    position: relative;
    font-size: 0.8em;
    @include media-breakpoint-up(sm) { 
      font-size: 1em;
      padding: 2em 3em;
    }
  }
  button.close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
  button.btn.modal-close {
    @extend %btn-green;
  }
}

.modal-backdrop.show {
  opacity: 0.7;
}

.noScroll {
	overflow: hidden;
}

svg {
  cursor: pointer;
  path, circle {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
}