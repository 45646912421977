@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir/AvenirLTStd-Book.woff2') format('woff2'),
        url('../fonts/Avenir/AvenirLTStd-Book.woff') format('woff'),
        url('../fonts/Avenir/AvenirLTStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir/AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('../fonts/Avenir/AvenirNextLTPro-Demi.woff') format('woff'),
        url('../fonts/AvenirNextLTPro-Demi.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('../fonts/Avenir/AvenirNextLTPro-Bold.woff') format('woff'),
        url('../fonts/Avenir/AvenirNextLTPro-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nilland';
    src: url('../fonts/Nilland/Nilland.woff2') format('woff2'),
        url('../fonts/Nilland/Nilland.woff') format('woff'),
        url('../fonts/Nilland/Nilland.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nilland';
    src:url('../fonts/Nilland/Nilland-Black.woff2') format('woff2'),
        url('../fonts/Nilland/Nilland-Black.woff') format('woff'),
        url('../fonts/Nilland/Nilland-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}