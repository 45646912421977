.sticky-nav {
	clip: rect(0, auto, auto, 0);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 4;
	pointer-events: none;

	ul.nav-links {
		margin: 0;
		padding: 0;
		position: fixed;
		top: 40%;
		opacity: 0;
		width: 7em;
		right: 0.5em;
		text-align: right;
		pointer-events: all;
		li {
			list-style: none;
			margin-bottom: 0.3em;
			transition: all 0.3s ease;
			&:hover {
				padding-right: 0.2em;
			}
			a {
				font-weight: 500;
				color: $nav-links;
				text-transform: uppercase;
				&:hover {
					color: $link-text-hover !important;
					text-decoration: none;
				}
			}
		}
	}
	.navmenu-btn {
		cursor: pointer;
		border: 1px solid $nav-btn-color;
		padding:  0.8em 0.7em;
		position: absolute;
		width: 3em;
		right: 0;
		top: 0.8em;
		opacity: 0;
		position: fixed;
		pointer-events: all;
		@include media-breakpoint-up(md) { 
			background-color: transparent;
			border: none;
			padding:  0;
			width: 1.8em;
			right: 0.5em;
			top: 2em;
			transition: all 0.1s ease;
		}
		span {
			display: block;
			background-color: $nav-links;
			height: 3px;
			margin-bottom: 0.4em;
			width: 100%;
			&:nth-child(2) {
				@include media-breakpoint-up(md) { 
					width: 70%;
					margin-left: 30%;
				}
			}
			&:last-child {
				margin-bottom: 0;
				@include media-breakpoint-up(md) { 
					width: 35%;
					margin-left: 65%;
				}
			}
		}
		&:hover {
			border-color: $nav-links-hover !important;
			@include media-breakpoint-up(md) { 
				padding-right: 0.2em;
			}
			span {
				background-color: $nav-links-hover !important;
				text-shadow: 0 0 0 0.5em rgba(0,0,0,0.7);
			}
		}
	}
	&.mobile-stick {
		background-color: rgba(0,0,0, 0.65);
		border-bottom: 1px solid white;
		.navmenu-btn {
			border: none;
			border-left: 1px solid white;
			padding: 1.45em 0.8em;
			width: 4em;
			right: 0;
			top: 0;
			span:nth-child(-n+2) {
				margin-bottom: 0.5em;
			}
		}
	}

	&.nav-green {
		ul.nav-links li a {
			color: $nav-links-sec;
			text-shadow: 0 0 2px white;
		}
		.navmenu-btn {
			border-color: $nav-links-sec; 
			span {
				background-color: $nav-links-sec;
			}
			@include media-breakpoint-down(sm) {
				background-color: $nav-links-sec;
				span {
					background-color: $nav-links !important;
				}
				&:hover {
					background-color: $nav-links-hover;
				}
			}
		}
	}
}


.navbar {
	background: url('../img/nav-bg.jpg') center;
	background-size: cover;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 100;
	visibility: hidden;
	width: 300px;
	height: 100%;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	transform: translateX(-300px);
	z-index: 1;
	&:after {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
		content: '';
		opacity: 1;
		-webkit-transition: opacity 0.5s;
		transition: opacity 0.5s;
	}

	a {
		color: $nav-links;
	}
	.navbar-content {
		position: absolute;
		top: 1em;
		right: 1.5em;
		@include media-breakpoint-up(md) { 
			right: 3em;
		}
		ul.navmenu {
			font-family: 'Nilland';
			text-align: right;
			position: relative;
			padding-right: 1.2em;
			margin: 4em 0 0 0;
			text-align: right;
			@include media-breakpoint-up(md) { 
				padding: 0;
			}
			li {
				list-style: none;
				position: relative;
				margin-bottom: 0.5em;
				transition: all 0.3s ease;
				&:after {
					content: "";
					background-color: $menu-bullet-color;
					height: 0.5em;
					position: absolute;
					width: 0.5em;
					right: -1.2em;
					top: 0.75em;
				}
				a {
					color: $nav-links;
					text-transform: uppercase;
					font-size: 1.2em;
					font-weight: 800 ;
					text-transform: none;
					&:hover {
						color: $menu-link-hover;
						text-decoration: none;
					}
				}
			}
		}
	}

	.nav-close{
		position: relative;
		margin: auto;
		width: 1.8em;
		height: 1.8em;
		margin-right: -1.2em;
		margin-top: 0.5em;
		cursor: pointer;
		.leftright{
			height: 3px;
			width: 1.8em;
			position: absolute;
			margin-top: 1em;
			background-color: $nav-btn-color;
			transform: rotate(45deg);
			transition: all .3s ease-in;
		}
		.rightleft{
			height: 3px;
			width: 1.8em;
			position: absolute;
			margin-top: 1em;
			background-color: $nav-btn-color;
			transform: rotate(-45deg);
			transition: all .3s ease-in;
		}
		label{
			color: $nav-btn-color;
			font-family: 'Avenir';
			font-weight: normal;
			font-size: .5em;
			text-transform: uppercase;
			letter-spacing: 2px;
			transition: all .3s ease-in;
			opacity: 0;
		}
		.close{
			margin: 4.5em 0 0 -2em;
			@include media-breakpoint-up(md) { 
				margin: 4.5em 0 0 0;
			}
			position: absolute;
		}

		&:hover .leftright{
			transform: rotate(-45deg);
			background-color: $nav-btn-hover;
		}
		&:hover .rightleft{
			transform: rotate(45deg);
			background-color: $nav-btn-hover;
		}
		&:hover label{
			opacity: 1;
		}
	}
}

.st-container, .st-pusher, .st-content {
	height: 100%;
}

.st-content, .st-content-inner {
	position: relative;
	background-color: white;
}

.st-container {
	position: relative;
	overflow: hidden;
}

.st-pusher {
	position: relative;
	left: 0;
	z-index: 3;
	height: 100%;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	&:after {
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		background: $nav-open-overlay;
		content: '';
		opacity: 0;
		-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		z-index: 5;
	}
}

.st-menu-open {
	.st-pusher::after {
		width: 100%;
		height: 100%;
		opacity: 1;
		-webkit-transition: opacity 0.5s;
		transition: opacity 0.5s;
	}
	.st-menu::after {
		width: 0;
		height: 0;
		opacity: 0;
		-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	}
	.st-pusher {
		transform: translateX(-300px);
	}	
	.st-menu {
		visibility: visible;
		-webkit-transition: -webkit-transform 0.5s;
		transition: transform 0.5s;
		// -webkit-transform: translate3d(0, 0, 0);
		transform: translateX(0);
	}
}

