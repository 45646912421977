#menu {	
	background: url('../img/bg-marble.jpg') no-repeat;
	background-position: center; 
	background-size: cover;
	padding: 3em 0;
	@include media-breakpoint-up(md) { 
		background: url('../img/symbol-menu.svg') no-repeat, url('../img/bg-marble.jpg') no-repeat;
		background-position: left 5% bottom 3em, center; 
		background-size: 200px auto, cover;
		padding: 10em 0 13em;
	}
	.menu-teaser {
		width: 92%;
		img.category-title {
			height: auto;
			position: absolute;
			width: 6em;
			bottom: calc(50% - 3em);
			right: -2em;
			transition: all 0.3s  $ease-out;
			@include media-breakpoint-up(md) { 
				bottom: -3.5em;
				left: auto !important;
				right: 50% !important;
				transform: translateX(50%) !important;
				width: 7em;
			}
		}	
		&:nth-child(2n-2) {
			margin-left: 8%;
			.category-title {
				right: auto;
				left: -2em;
			}
		}
		@include media-breakpoint-up(md) { 
			width: 100%;
			&:nth-child(2n-2) {
				margin-left: 0;
			}
		}
		&:hover {
			img.category-title {
				@include media-breakpoint-up(md) { 
					margin-bottom: calc(50% + 2.5em);
				}
			}
		}

		.menu-teaser-btn {
			background-position: center;
			background-size: cover;
			border: none;
			height: 10em;
			position: relative;
			margin-bottom: 1em;
			width: 100%;
			@include media-breakpoint-up(sm) { 
				height: 12em;
			}
			@include media-breakpoint-up(md) { 
				background-position: bottom center;
				height: 30em;
			}
			&.sides {
				background-image: url('../img/menu-teaser-sides_mob.jpg');
				@include media-breakpoint-up(md) {
					background-image: url('../img/menu-teaser-sides.jpg');
				}
			}
			&.mains {
				background-image: url('../img/menu-teaser-mains_mob.jpg');
				@include media-breakpoint-up(md) {
					background-image: url('../img/menu-teaser-mains.jpg'); 
					margin-top: -4em;
				}
			}
			&.drinks {
				background-image: url('../img/menu-teaser-drinks_mob.jpg');
				@include media-breakpoint-up(md) { 
					background-image: url('../img/menu-teaser-drinks.jpg');
					margin-top: -2em;
				}
			}
		}
	}
}

#menuModal {
	background-color: white;
	padding: 0 !important;
	.modal-dialog {
		border: none;
		border-radius: 0 !important;
		color: $body-text-color;
		width: 100%;
		margin: 0;
		max-width: 100%;
		min-height: 100%;
		@include media-breakpoint-up(md) { 
			overflow: hidden;
		}
		.modal-content {
			border: 0;
			@include media-breakpoint-up(md) { 
				display: flex;
				flex-direction: row;
			}

			.social-media {
				position: absolute;
				right: 4em;
				text-align: center;
				svg.social-icon {
					.icon-color {
						fill: $nav-links !important;
					}
					&:hover {
						.icon-color {
							fill: $nav-btn-hover !important;
						}
					}
				}
			}
			.menu-container {
				position: absolute;
				width: 100%;
				left: 0;
				top: 0;
				opacity: 0;
				@include media-breakpoint-up(md) { 
					width: 85%;
					flex-grow: 2;
				}
				.menu-category {
					position: relative;
					min-height: 100%;
					flex: 0 0 auto;
					.menu-content {
						border: 0;
						border-radius: 0;
						width: 100%;
						@include media-breakpoint-up(md) { 
							width: 50%;
							height: 100%;
						}
						&.menu-img {
							@extend %menu-image;
							background-color: transparent;
						}
						&.menu-info {
							// height: calc(80vh - 3em);
							padding: 4% 4.5%;
							@include media-breakpoint-up(md) { 
								height: 100vh;
								overflow-y: auto;
							}
						}

						.menu-items-container {
							@extend %active-state;
							.menu-item {
								margin-bottom: 1.7em;
							}
							h4, .key span {
								display: inline;
								position: relative;
								margin-bottom: 0.2em;
								&.vege:before {
									content: "";
									background-color: $symbol-vege;
									position: absolute;
									height: 0.6em;
									top: 0.4em;
									right: -1em;
									width: 0.6em;
								}
								&.special:after {
									content: "";
									background: url('../img/icon-chopsticks.svg') no-repeat;
									background-position: center;
									background-size: 25px auto;
									position: absolute;
									height: 20px;
									bottom: 0;
									right: -1.3em;
									width: 18px;
								}
								&.both:after {
									right: -2.5em;
								}
							}
							.key {
								span.vege:before {
									font-size: 1.5em;
									left: -1em;
									top: 0.1em;
									right: auto;
								} 
								span.special:after {
									left: -1.3em;
									top: -0.2em;
									right: auto;
								}
							}
							.highlight {
								display: block;
								font-weight: bold;
							}
							.menu-category-img {
								@extend %menu-image;
								background-size: cover;
								background-position: bottom center;
								position: absolute;
								margin-left: -100%;
								left: 0;
								bottom: 0;
								opacity: 0;
								transition: ease-in 3s;
								.caption {
									background-color: rgba(0,0,0,0.3);
									color: white;
									display: block;
									position: absolute;
									width: 100%;
									padding: 0.5em;
									bottom: 0;
									left: 0;
									text-align: center;
								}
							}

							&.active {
								.menu-category-img {
									margin-left: 0;
									opacity: 1;
								}
							}
						}
						
						.selection-container {
							margin: 0.6em 0;
							padding: 0;
							text-align: center;
							@include media-breakpoint-up(md) { 
								margin: 1em 0 1.4em;
							}
							@include media-breakpoint-up(xxl) { 
								margin: 2.5em 0 3.5em;
							}
							li {
								display: inline-block;
								list-style: none;
								svg {
									border: 1px solid $title-box-fill;
									margin: 0 0.2em 0.5em ;
									height: 4em;
									width: 4em;
									@include media-breakpoint-up(md) { 
										margin: 0.5em ;
										height: 4.5em;
										width: 4.5em;
									}
									transition: all .3s ease;
									.fill-1 {
										fill: $title-box-fill;
									}
									.fill-2 {
										fill: white;
									}
									.stroke {
										stroke: $title-box-fill;
										fill: white;
										stroke-width: 0.7;
									}
									&:hover {
										border-color: $title-box-hover;
										.fill-1 {
											fill: $title-box-hover;
										}
										.fill-2 {
											fill: white;
										}
										.stroke {
											stroke: $title-box-hover;
											fill: none;
										}
									}
									&.active {
										border-color: $title-box-fill;
										pointer-events: none;
										.fill-1 {
											fill: white;
										}
										.fill-2 {
											fill: $title-box-fill;
										}
										.stroke {
											stroke: white;
											fill: $title-box-fill;
											stroke-width: 1;
										}
									}
								}
							}
						}
					}
				}
			}
			
			.menu-sidebar {
				align-items: center;
				justify-content: center;
				background-color: $modal-sidebar;
				bottom: 0;
				color: $nav-btn-color;
				font-family: 'Avenir';
				position: fixed;
				width: 100%;
				min-height: 3em;
				@include media-breakpoint-up(md) {
					top: 0;
					right: 0;
					width: 15%;
					height: 100vh;
				}
				.menu-close {
					background: none;
					border: none;
					color: $nav-btn-color;
					cursor: pointer;
					font-size: 2.5em;
					margin: -0.1em 0.7em 0 0;
					position: absolute;
					right: 0.2em;
					top: -0.1em;
					transition: all 0.3s $ease-out;
					@include media-breakpoint-up(md) { 
						left: 50%;
						margin: 0;
						top: 1em;
						transform: translateX(-52.5%);
					}
					&:hover {
						color: $nav-links-hover;
					}
				}
				.menu-controls {
					padding: 1.5em 0;
					margin: 0;
					position: relative;
					width: 7.5em;
					@include media-breakpoint-up(md) { 
					}
					li {
						display: block;
						font-weight: 800;
						list-style: none;
						line-height: 1.3em;
						margin: 0;
						padding: 0.5em;
						position: absolute;
						left: 50%;
						top: 50%;
						text-align: center;
						transform: translate(-50%,-50%);
						width: 100%;
						cursor: pointer;
						text-transform: uppercase;
						@include media-breakpoint-up(md) { 
							display: none;
						}
						&:hover {
							color: $nav-btn-hover;
						}
					}
					&.control-top {
						span {
							margin-top: 0.9em;
							@include media-breakpoint-up(md) { 
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
}


.menu-arrow {
	cursor: pointer;
	display: block;
	height: 1em;
	margin: 0 auto;
	position: relative;
	width: 3em;
	&:before {
		content: '';
		background-color: $nav-btn-color;
		height: 1px;
		position: absolute;
		top: 0.2em; 
		left: 0;
		width: 100%;
	}
	&:after {
		content: '';
		display: block;
		margin: 0;
		padding: 0;
		position: absolute;
		width: 0.5em;
		height: 0.5em;
		top: 0;
		right: 0;
		border-top: 1px solid $nav-btn-color;
		border-right: 1px solid $nav-btn-color;
		transform: rotate(40deg);
	}

	&.left {
		transform: rotate(180deg);
	}

	&:hover {
		&:after {
			border-color: $nav-btn-hover;
		}
		&:before {
			animation: arrowWidth 0.3s cubic-bezier(1, 0.6, 0, 1);
			background-color: $nav-btn-hover;
		}
	}
}

@keyframes arrowWidth {
	0% {
			width: 0;
	}
	100% {
		width: 100%;
	}
}