.logo {
	position: absolute;
	height: auto;
	margin-top: 1.2em;
	margin-left: 0;
	opacity: 0;
	z-index: 3;
	@include media-breakpoint-up(md) { 
		margin-top: 2%;
		margin-left: calc(2% - 10px);
	}
	img { 
		width: 12em;
		@include media-breakpoint-up(md) { 
			width: 12em;
		}
		@include media-breakpoint-up(xl) { 
			width: 14em;
		}
		@include media-breakpoint-up(xxl) { 
			width: 16em;
		}
	}
}

#landing {
	background-position: center;
	background-size: cover;
	height: 100vh;
	overflow: hidden;

	.video-container {
		background: url('../img/forebidden-hero-banner.jpg') center;
		background-size: cover;
		height: 100%;
		min-height: 100vh;
		overflow: hidden;
		position: relative;
		width: 100%;
		z-index: 1;
		video {
			min-width: 100%;
			min-height: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.landing-content {
		font-size: 0.8em;
		position: absolute;
		max-width: 9em;
		margin-left: -4.5em;
		margin-top: -20px;
		top: 50%;
		left: 50%;
		z-index: 2;
		opacity: 0;
		@include media-breakpoint-up(lg) { 
			max-width: 10em;
			margin-left: -5em;
		}
		@include media-breakpoint-up(xxl) { 
			max-width: 12em;
		}
		&.home {
			max-width: 15em;
			margin-left: -7.5em;
			margin-top: -8em;
			top: 47%;
			img {
				max-width: 6em;
				@include media-breakpoint-up(lg) {
					max-width: 8em;
				}
			}
			h1 {
				font-size: 2em;
			}
		}
		.title-image {
			display: block;
			height: auto;
			margin: auto;
			margin-bottom: 1em;
			max-width: 9em;
			width: 100%;
		}
	}

	.scroll-box {
		bottom: 10%;
		color: white;
		font-size: 0.7em;
		left: 50%;
		position: absolute;
		text-transform: uppercase;
		transform: translate(-50%, 0);
		transition: all 0.1s  $ease-out;
		z-index: 2;
		&::after {
			content: "";
			animation: lineSide 2s cubic-bezier(1, 0, 0, 1) infinite;
			background-color: white;
			top: 2em;
			left: 50%;
			height: 10em;
			position: absolute;
			width: 1px;
		}
	}
}

@keyframes lineDown {
	0% {
			transform-origin: 0% 0%;
			transform: scale(1, 0);
	}
	50% {
			transform-origin: 0% 0%;
			transform: scale(1, 1);
	}
	50.1% {
			transform-origin: 0% 100%;
			transform: scale(1, 1);
	}
	100% {
			transform-origin: 0% 100%;
			transform: scale(1, 0);
	}
}
