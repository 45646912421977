#location {
	background: $location-bg url('../img/symbol-location.svg') no-repeat;
	background-position: right -2em top 0.5em; 
	background-size: 13.5em auto;
	text-align: left;
	@include media-breakpoint-up(md) {
		background-size: 23em auto;
		background-position: right -0.3em top 3em; 
	}

	.google-map {
		border: 0;
		width: 100%;
		height: 15em;
		@include media-breakpoint-up(sm) {
			height: 28em;
		}
		@include media-breakpoint-up(md) {
			height: 48em;
			width: 96%;
		}
	}

	.location-info {
		@include media-breakpoint-up(md) {
			margin-top: 9em;
		}

		.location-icons {
			margin-top: -0.7em;
		}

		.times {
			text-align: left;
			margin: 0 0 1em;
			max-width: 27em;
			padding: 0;
		}
	}
}