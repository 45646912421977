.rellax-element {
	background-color: $rellax-bg;
	overflow: hidden;
	position: relative;

	.rellax-fixed {
		width: 140%;
		position: absolute;
		left: 50%;
		top: -10%;
		transform: translateX(-50%);
		@include media-breakpoint-up(xl) {
			width: 130%;
			top: -30%;
		}
		img {
			min-height: 100%;
			min-width: 100%;
			object-fit: cover;
			width: 100%;
			height: auto;
		}
	}
}

// #menu {
// 	.rellax-element {
// 		height: 100%;
// 		width: 100%;
// 		.rellax-fixed {
// 			height: 170%;
// 			top: -45%;
// 		}
// 	}
// }

// .section-1 .rellax-element {
// 	height: 100%;
// 	width: 100%;
// 	.rellax-fixed {
// 		height: 100%;
// 		width: 100%;
// 		top: -10%;
// 		transform: translateX(-60%);
// 		img {
// 			height: 150%;
// 		}
// 	}
// }