.section {
	height: 100%;
	padding: 6.5em 0 5em;
	position: relative;
	width: 100%;
	text-align: center;
	@include media-breakpoint-up(md) { 
		padding: 6em 0;
	}
	&.section-1 {
		min-height: 100vh;
		padding: 0;
	}
	&.two-col {
		padding: 0;
	}
	.section-title {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 2;
		margin-left: -2.7em;
		&.center {
			@include media-breakpoint-up(md) {
				margin: 0;
				left: 28.5%; 
				top: 3.5em;
				transform: translateX(0);
			}
			@include media-breakpoint-up(lg) { 
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&.left {
			@include media-breakpoint-up(md) { 
				transform: translateX(-50%);
				margin: -1em 0 0 0;
				left: auto;
				top: auto;
				transform: none;
			}
		}
		img {
			height: auto;
			max-width: $title-size-xs;
			@include media-breakpoint-up(md) { 
				max-width: $title-size-md;
			}
			@include media-breakpoint-up(xl) { 
				max-width: $title-size-xl;
			}
		}
	}

	.title-content {
		margin-bottom: 1.6em;
		@include media-breakpoint-up(md) { 
			margin-bottom: 4em;
			padding: 0 18%;
		}
	}
}