%menu-image {
	height: 25em;
	pointer-events: none;
	width: 100%;
	margin-bottom: 3em;
	@include media-breakpoint-up(md) { 
		min-height: 100vh;
		width: 50%;
		margin-bottom: 0;
	}
}