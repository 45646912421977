#confirmation-popup {
	position: fixed;
	background-color: rgba(0,0,0,0.7);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	opacity: 0;
	pointer-events: none;
	.popup-content {
		display: none;
		background-color: $popup-content-bg;
		color: white;
		padding: 2em;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 90%;
		@include media-breakpoint-up(sm) { 
			width: auto;
		}
		@include media-breakpoint-up(md) { 
			padding: 4em 7em;
		}
		h2 {
			color: white;
		}
		img {
			width: 6.5em;
			height: auto;
			margin-bottom: 1em;
			@include media-breakpoint-up(md) { 
				width: 8.5em;
			}
		}
		button.popup-close {
			background: white;
			border: none;
			color: $popup-content-bg;
			font-weight: 500;
			padding: 0.5em 2em;
			text-align: center;
			text-transform: uppercase;
			transition: ease all 0.3s;
			&:hover {
				background-color: $btn-hover;
				color: white;
			}
		}
	}
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}