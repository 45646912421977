#about {
	clear: both;
	.table-container {
		@include media-breakpoint-up(md) { 
			display: flex;
			flex-direction: row;
		}
		.col-left {
			align-items: stretch;
			background: url('../img/home-about-bg.jpg') no-repeat center;
			background-size: cover;
			display: none;
			@include media-breakpoint-up(md) { 
				min-height: 100vh;
				display: block;
			}
			@include media-breakpoint-up(lg) { 
				background: $space-bg;
			}
		}
		.col-right {
			align-items: stretch;
			background: url('../img/symbol-about.svg') no-repeat, #DADADA;
			background-position: right 7% bottom 1em, center; 
			background-size: 12em auto;
			padding: 8em 2em 3em;
			@include media-breakpoint-up(md) { 
				min-height: 100vh;
				padding: 11em 18% 12em 10%;
			}
			@include media-breakpoint-up(lg) { 
				background-position: right 12% bottom 1em, center; 
				background-size: 14em auto;
				padding: 11em 18% 10em 7%;
			}
			@include media-breakpoint-up(xl) { 
				padding-top: 10em;
				.about-content {
					margin-top: 10vh;
				}
				@media only screen and (min-width: 2500px) { 
					.about-content {
						margin-top: 20vh;
					}
				}
			}
		}
	}
}

#gallery {	
	background: url('../img/bg-marble.jpg') no-repeat;
	background-size: cover;
	@include media-breakpoint-up(md) { 
		background: url('../img/symbol-gallery.svg') no-repeat, url('../img/bg-marble.jpg') no-repeat;
		background-position: right 5% top 3em, center; 
		background-size: 11em auto, cover;
	}
	@include media-breakpoint-up(lg) { 
		background-size: 14em auto, cover;
	}
	.gallery-container {
		.card-img-top {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}

#contact {
	@include media-breakpoint-up(md) { 
		background: url('../img/symbol-contact.svg') no-repeat;
		background-position: right -0.2em bottom 3em; 
		background-size: 20em auto;
		padding-bottom: 15em;
	}
	@include media-breakpoint-up(lg) { 
		background-size: 25em auto;
		padding-bottom: 18em;
	}
	@include media-breakpoint-up(xl) { 
		padding-bottom: 12em;
	}

	.contact-form {
		text-align: left;
		max-width: 600px;
		margin: auto;
		.form-group {
			border-bottom: 1px solid $border-color;
			label {
				line-height: 2.4em;
				margin-bottom: 0;
				width: 9em;
				@include media-breakpoint-up(md) { 
					line-height: 2.5em;
				}
				@include media-breakpoint-up(lg) { 
					line-height: 2em;
				}
			}
			input {
				background-color: transparent;
			}
			.form-control {
				border: none;
				border-radius: 0;
				margin-bottom: 0;
				&::placeholder {
					color: $placeholder-color;
				}
			}

			&.form-select {
				@include media-breakpoint-only(lg){
					position: relative;
					&:after {
						content: "";
						position: absolute;
						right: 0.3em;
						top: 0.8em;
						width: 0; 
						height: 0; 
						border-left: 0.3em solid transparent;
						border-right: 0.3em solid transparent;
						border-top: 0.5em solid $space-bg;
						pointer-events: none;
						@include media-breakpoint-up(sm) { 
							top: 1.1em;
						}
					}
				}
				select {
					background-color: transparent;
				}
			}
		}

		.form-booking-container {
			flex-wrap: wrap;
			.form-group {
				@include media-breakpoint-up(md) { 
					width: 33%;
					&:nth-child(n+2) {
						width: 25.5%;
						label {
							width: 5em;
						}
					}
				}
				@include media-breakpoint-up(xxl) { 
					width: 33%;
				}
			}
			.form-helper {
				width: 100%;
				margin-bottom: 1.5em;
				.form-text {
					line-height: 1.5em;
					font-size: 0.75em;
				}
				.badge-warning {
					font-size: 1em;
					padding: 0.7em 0.5em 0.5em !important;
					margin-right: 0.7em;
					float: left;
					margin-top: 0.1em !important;
					margin-bottom: 0.5em;
				}
			}
			#PaxInput {
				@include media-breakpoint-up(md) { 
					width: 6.5em;
				}
			}
		}

		.checkboxes-container {
			margin: 2.5em 0;
		}
		.form-check {
			text-align: left;
			.form-check-input , .form-check-label {
				cursor: pointer;
			}
		}

		#contactInput::-webkit-inner-spin-button, 
		#contactInput::-webkit-outer-spin-button { 
			-webkit-appearance: none; 
			margin: 0; 
		}

		#bookingDate {
			cursor: pointer;
		}

		.has-error {
			border: 1px solid $error !important;
			position: relative;
		}

		input[type=checkbox].has-error:after {
			content: "";
			width: 11px;
			height: 11px;
			border-radius: 2px;
			box-shadow: 0px 0px 5px 1px rgba(255,0,0,1);
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;
		}
		.form-submit {
			position: relative;
			.btn {
				@extend %btn-green;
			}
		}
	}
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: loader 1.8s infinite ease-in-out;
  animation: loader 1.8s infinite ease-in-out;
}
.loader {
	display: none;
  color: white;
  font-size: 3px;
  margin-top: 2.2em;
	position: absolute;
	right: calc(50% - 19em);
	transform: translateX(-50%);
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
	&:before, &:after {
		content: '';
		position: absolute;
		top: 0;
	}
	&:before {
		left: -4em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	&:after {
		left: 4em;
	}
}
@-webkit-keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}