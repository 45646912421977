.datepicker {
	table tr td {
		&.day {
			border: 1px solid $datepick-today !important;
			border-radius: 0;
			&.today {
				background-color: $datepick-today !important;
				background-image: none !important;
				color: $datepick-active;
				&:hover {
					background-color: $datepick-hover!important;
					color: white;
				}
			}
			&.active, &:active:hover {
				background-color: $datepick-active !important;
				background-image: none !important;
				color: white;
			}
			&:hover{
				background: $red!important;
				background-color: $datepick-hover!important;
				color: white !important;
			}
		}
		.month, .year {
			&.focused {
				background: $datepick-active !important;
				background-color: $datepick-active !important;
				color: white;
			}
			&:hover {
				background: $datepick-hover !important;
				color: white;
			}
		}
	}
}