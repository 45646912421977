#loader-wrapper {
	#loader {
		z-index: 1001;
		position: fixed;
		max-width: 80%;
		width: 25em;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		img {
			width: 100%;
			height: auto;
		}
		> span {
			color: white;
			display: block;
			font-size: 0.7em;
			letter-spacing: 2px;
			margin-left: 10%;
			position: relative;
			padding-left: 5.5em;
			opacity: 0.8;
			text-align: left;
			@include media-breakpoint-up(md) { 
				font-size: 1em;
			}
			&:before {
				content: "";
				background-color: rgba(255,255,255,0.6);
				position: absolute;
				left: 0;
				top: 0.7em;
				height: 1px;
				width: 5em;
				animation: lineSide 2s cubic-bezier(1, 0, 0, 1) infinite;
			}
		}
	}

	.loader-section {
		position: fixed;
		top: 0;
		width: 51%;
		height: 100%;
		background: $green;
		transition: all 0.3s $ease-out;
		z-index: 1000;
		&.section-left {
			left: 0;
		}
		&.section-right {
			right: 0;
		}
	}

	&.hide {
		transition: all 0.3s 0.6s $ease-out;

		.loader-section {
			&.section-left {
				transform: translateX(-100%);
			}
			&.section-right {
				transform: translateX(100%);
			}
		}
	}
}

@keyframes lineSide {
	0% {
			transform-origin: 0% 0%;
			transform: scale(0, 1);
	}
	50% {
			transform-origin: 0% 0%;
			transform: scale(1, 1);
	}
	50.1% {
			transform-origin: 0% 100%;
			transform: scale(1, 1);
	}
	100% {
			transform-origin: 0% 100%;
			transform: scale(1,0);
	}
}