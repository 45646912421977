.scroll-indicator {
	// pointer-events: none;
	position: fixed;
	top: 50%;
	left: 2%;
	transform: translateY(-50%);
	z-index: 10;
	.noodles {
		margin-top: -5px;
		margin-left: 1em;
		width: 15px !important;
		background-image: url('../img/scroll-noodles-white.png');
		background-repeat: repeat-y;
		background-size: auto 15px;
		height: 198px;
	}
	svg {
		path {
			fill: white;
		}
	}

	&.svg-green {	
		svg {
			path {
				fill: $mobile-menu-btn;
			}
		}
		.noodles {
			background-image: url('../img/scroll-noodles-green.png');
		}
	}
}