footer {
	background-color: $footer-bg;
	color: white;
	font-size: 0.8em;
	padding: 1em 3%;
	width: 100%;
  @include media-breakpoint-up(md) { 
    display: flex;
    align-items: center;
  }
  a {
    color: $footer-link;
    text-decoration: underline;
  }

	.forefront-logo {
    display: block;
    height: 18px;
    margin: 0.5em auto;
		width: auto;
		@include media-breakpoint-up(md) { 
      margin: 0 1em 0 0;
    }
	}
}